$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.service-footer {
    position: fixed;
    bottom: 0;
    max-width: 500px;

    background-color: $whiteColor2;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px -4px 10px rgba(111, 118, 136, 0.1);

    .notes {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1rem;
        color: $grayTextColor;
    }

    .btn-choose {
        background: $baseColor1;
        background: linear-gradient(40deg, $baseColor2 50%, $baseColor1 100%);

        outline: none;
        border: none;

        color: $baseColor4;
        border-radius: 50px;
        width: 100%;
        padding: 1rem;

        &.disabled {
            background: $boxOutlineColor;
            color: $whiteTextColor;
        }
    }
}