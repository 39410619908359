$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$topBarColor: var(--white-color);
$lightColor: var(--white-color-2);
$darkTextColor: var(--dark-text-color);
$borderColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.transaction-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

    padding-top: 140px;
    padding-bottom: 120px;

    .transaction-tab-container {
        position: fixed;
        top: 60px;
        width: 500px;

        background-color: $topBarColor;

        .transaction-tabs {
            width: 100%;
    
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            overflow-x: auto;
    
            .transaction-tab-item {
                min-width: 200px;
                // width: fit-content;
                text-align: center;
                padding: 0.6rem 0.3rem 0.4rem;
    
                border-bottom: 3px solid rgba(0, 0, 0, 0);
    
                &:hover,
                &.active {
                    font-weight: 600;
                    cursor: pointer;
                    border-color: $baseColor1;
                }
            }
        }
    }

 
    .transaction-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        width: 100%;


        .transaction-item {
            width: 95%;
            background-color: $lightColor;
            border: 1px solid #d7d6d6;
            border-radius: 20px;

            .transaction-item-header {
                padding: 0.5rem;

                display: flex;
                justify-content: space-between;

                border-bottom: 1px solid $borderColor;

                .header-label {
                    color: $grayTextColor;
                }
            }

            .transaction-item-body {
                padding: 0.5rem;

                display: flex;
                justify-content: flex-start;
                gap: 1rem;

                border-bottom: 1px solid $borderColor;

                .service-icon {
                    width: 30%;
                    max-width: 100px;
                    
                    img {
                        width: 100%;
                    }
                }

                .service-detail {
                    display: flex;
                    flex-direction: column;

                    .service-info {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }

                    .transaction-icon {
                        width: 13px;
                    }

                    .service-name {
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .datetime {
                        color: $grayTextColor;
                    }

                    .duration {
                        color: $grayTextColor;
                    }

                    .ends-on {
                        color: $grayTextColor;
                    }
                }
            }

            .transaction-item-footer {
                padding: 0.5rem;

                display: flex;
                justify-content: space-between;
                align-items: center;

                .action-button-container {
                    .btn-detail {
                        background-color: $baseColor2;
                        color: $lightColor;

                        border-radius: 20px;
                    }
                }
            }
        }
    }
}