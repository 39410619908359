$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.marquee {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    width: 100%;

    background-color: $baseColor3;
    padding: 1rem 2rem;
    font-size: 15px;
    line-height: 1.2rem;

    .marquee-icon {
        color: $baseColor4;
        font-size: 18px;
    }
}