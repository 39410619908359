$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$baseShadeColor: var(--base-shade-color);

$darkTextColor: var(--dark-text-color);
$contentBackgroundColor: #FBFBF8;

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);
$itemOutlineColor: var(--base-shade-color-2);

.legal-service {
    width: 100%;
    // background-color: rgb(246, 246, 246);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 0.8rem 2rem 2.5rem;

    // padding: 1.5rem 0;
    
    .legal-service-title {
        font-family: 'Ubuntu';
        font-weight: 500;
        font-size: 1.7rem;
        color: $darkTextColor;
    }

    .legal-service-loading-section {
        width: 100%;
        margin: 0.6rem 0 2rem;

        position: relative;

        .spinner-grow, .spinner-loading {
            background-color: $baseColor1;
        }
    }

    .legal-service-list {
        width: 100%;
        margin: 0.6rem 0 2rem;
    
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;

        .legal-service-item {
            // background-color: $baseColor4;            
            background-color: $contentBackgroundColor;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: first baseline;
            text-decoration: none;
            margin: 0 auto;

            gap: 1rem;

            position: relative;

            width: 100%;
            max-width: 150px;
            border-radius: 10px;
            padding: 0.8rem 1rem 0.3rem;

            border: 0.5px solid $itemOutlineColor;

            .legal-service-item-title {
                text-align: center;
                color: $darkTextColor;
                font-weight: 600;
                font-size: 12px;
            }

            .legal-service-icon-container {
                width: 80px;
                border-radius: 50%;
                // background-color: $baseShadeColor;

                .legal-service-item-icon {
                    width: 100%;
                    // margin-bottom: 0.8rem;
                }
            }
        }

    }

    @media (max-width: 500px) {
        .legal-service-title {
            font-size: 19px;
        }
        
        .legal-service-list {
            row-gap: 0.5rem;
            column-gap: 1rem;
    
            .legal-service-item {
                padding: 0.6rem 0.6rem 0.3rem;
    
                .legal-service-icon-container {
                    width: 60px;
                }
            }
        }
        
    }

    .legal-service-notes {
        align-self: flex-start;

        &-item {
            display: block;
            font-size: 13px;
        }
    }
}