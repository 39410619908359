$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$whiteColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);
$darkTextColor: var(--dark-text-color);
$grayColor: var(--gray-text-color);

.business-entity {
    padding: 0.8rem 0 0.8rem;
    
    .business-entity-header {
        h2 {
            font-family: 'Ubuntu';
            font-weight: 500;
            font-size: 1.7rem;
            color: $darkTextColor;
        }
        
        margin: 0 2rem;
    }

    .business-entity-carousel-container {
        width: 100%;
        height: fit-content;
        padding: 0.6rem 1rem;

        .business-entity-item-holder {
            // width: 100px;
            max-width: 150px;
            padding-right: 20px;
            // margin: 0 auto;

            .business-entity-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;

                text-decoration: none;

    
                .business-entity-icon {
                    width: 65px;
                    // background-color: $grayColor;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    border-radius: 50%;
    
                    img {
                        width: 100%;
                    }
                }
    
                .business-entity-name {
                    font-size: 12px;
                    text-align: center;
                    color: $darkTextColor;
                    width: 90%;
                    max-width: 80px;
                }
            }
        }

    }

    @media (max-width: 500px) {
        .business-entity-header {
            h2 {
                font-size: 19px;
            }
        } 
    }
}