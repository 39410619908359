$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);
$contentBackgroundColor: #FBFBF8;

.event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    gap: 1rem;
    width: 100%;
    padding: 1rem 2rem;

    .event-subtitle {
        align-self: flex-start;
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 0.7rem;
    }
    
    .event-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 100%;

        .event-empty-text {
            text-align: center;
            color: $grayTextColor;
        }

        .event-choice {
            text-decoration: none;
            color: initial;
        }
    }
}