$darkTextColor: var(--dark-text-color);
$buttonBackgroundColor: #FDFDF8;
$outlineColor: var(--base-shade-color-2);

.customer-complain-container {
    padding: 1rem 2rem 0;
    width: 100%;

    .customer-complain {
        width: 100%;
        text-decoration: none;
        color: $darkTextColor;
        background-color: $buttonBackgroundColor;
        border: 1px solid $outlineColor;
        padding: 1rem;

        font-size: 14px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 10px;
        gap: 1rem;

        .customer-complain-header {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .header-icon {
                font-size: 14px;
            }

            font-size: 17px;
            font-weight: 500;
        }

        .customer-complain-info {
            text-align: center;
        }
    }

    @media (max-width: 500px) {
        .customer-complain {
            font-size: 15px;
        }
    }
}