$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);
$baseShadeColor: var(--base-shade-color);
$baseShadeColor2: var(--base-shade-color-2);

$darkTextColor: var(--dark-text-color);
$grayTextColor: var(--gray-text-color);
$whiteColor: var(--white-color);
$whiteColor2: var(--white-color-2);

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.corporate-package-option-container{
    padding: 0 0 2.5rem;
    width: 100%;
    max-width: 500px;

    .corporate-package-option {
        gap: 1rem;
        width: 100%;
        max-width: 500px;
        padding: 2.5rem 0 0;
        // height: 100%;

        .package-item-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 500px;
            
            .corporate-package-item {
                background-color: $whiteColor2;
                border: 1px solid $baseShadeColor2;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                position: relative;
    
                min-width: 350px;
                width: 30%;
                max-width: 400px;
                
                border-radius: 40px;
                
                padding: 1rem 2rem 1.5rem;
    
                .corporate-package-item-title {
                    text-align: center;
                    color: $primaryTextColor;
                    background-color: $whiteColor;
                    font-size: 20px;
                    padding: 0.6rem 1rem;
                    border-radius: 15px;
                    border: 1px solid $baseShadeColor2;

                    position: absolute;
                    top: -25px;
                }
    
                .corporate-package-item-desc-list {
                    .corporate-package-item-desc {
                        padding: 0 0.2rem;
                        // color: $baseColor2;
                        color: $darkTextColor;
    
                        .desc-icon {
                            color: $baseShadeColor;
                        }
                    }
                }
    
                .corporate-package-item-desc-list {
                    padding: 0;
    
                    & li {
                        // list-style-type: none;
                        list-style-type: disc;
                    }
                }
    
                .corporate-package-item-icon {
                    width: 70%;
                    
                    img {
                        width: 100%;
                    }
                }
    
                .corporate-package-item-label {
                    font-weight: 500;
                    margin-top: 15px;
                    
                    .corporate-package-item-real-price {
                        text-decoration: line-through;
                        font-weight: 700;
                    }
                }

                .corporate-package-item-price {
                    font-weight: 500;
                    // color: $baseColor1;
    
                    .corporate-package-item-discount-price {
                        color: $baseColor1;
                        font-size: 1.5rem;
                        font-weight: 700;
    
                        border-bottom: 3px solid $baseColor3;
                        line-height: 1.2rem;
    
                        margin: 1rem 0;
                    }
    
                    &.corporate-package-item-save-percentage {
                        font-size: 1.2rem;
                    }
                }
    
                .btn-corporate-package {
                    // position: absolute;
                    // bottom: 30px;
    
                    margin-top: 40px;
                    padding: 10px 20px;
                    font-weight: 500;
    
                    text-decoration: none;
                    
                    background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    -webkit-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    -moz-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    color: $textColor;
    
                    border-radius: 20px;
    
                    transition: all 0.5s ease-in-out;
    
                    &:hover, &:focus, &:active {
                        background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        -webkit-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        -moz-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        color: $primaryTextColor;
                    }
                }
            }
        }

    }

    .corporate-package-footer {
        position: fixed;
        bottom: 0;
        max-width: 500px;

        background-color: $whiteColor2;
        width: 100%;
        padding: 1rem;
        box-shadow: 0px -4px 10px rgba(111, 118, 136, 0.1);

        .notes {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 1rem;
            color: $grayTextColor;
        }

        .btn-choose {
            background: $baseColor1;
            background: linear-gradient(40deg, $baseColor2 50%, $baseColor1 100%);

            outline: none;
            border: none;

            color: $baseColor4;
            border-radius: 50px;
            width: 100%;
            padding: 1rem;

            text-decoration: none;

            display: block;
            text-align: center;
        }
    }
}