$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.custom-qty-modal {
    font-family: 'JosefinSans';
    
    .modal-content {
        background-color: $whiteTextColor;

    }

    .modal-title {
        
    }

    .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .qty-input {
            display: block;
            width: 100px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.375rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .qty-select {
            display: block;
            width: 100px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.375rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .qty-unit {

        }
    }

    .modal-footer {
        justify-content: center;

        .btn-cancel {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;
        }

        .btn-confirm {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;

            background: $baseColor1;
            background: linear-gradient(60deg, $baseColor2 10%, $baseColor1 100%);
            color: $whiteColor2;
        }
    }
}

.service-opt {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    .qty-item {
        background-color: $whiteColor2;
        border: 1px solid $boxOutlineColor;
        padding: 0.4rem;
        border-radius: 10px;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;

        cursor: pointer;

        &.active {
            border-color: $baseColor1;
        }

        @media (max-width: 500px) {
            min-width: 80px;
        }
    }
}