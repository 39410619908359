$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$shadeColor2: var(--base-shade-color-2);

$whiteColor: var(--white-color);
$whiteColor2: var(--white-color-2);
$darkTextColor: var(--dark-text-color);
$grayColor: var(--gray-text-color);

.profile-page {
    padding: 80px 0 60px;

    background: linear-gradient(180deg, $baseColor4, $whiteColor);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

    .profile-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        width: 90%;

        .profile-picture {
            overflow: hidden;
            border-radius: 50%;
            border: 2px solid $baseColor1;
            width: 100px;
            height: 100px;

            img {
                width: 100%;
            }
        }

        .profile-name {
            font-size: 23px;
        }

        .profile-btn-login-container {
            width: 100%;
            height: 180px;
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            gap: 20px;
    
            .profile-btn-login {
                text-decoration: none;
                font-size: 18px;
                
                &.google {
                    background-color: $baseColor1;
                    background: linear-gradient(150deg, $baseColor1, $baseColor2);
                    color: $whiteColor2;

                    .profile-btn-icon {
                        width: 30px;
                        background-color: white;
                        border-radius: 50%;
                    }
                }

                &.apple {
                    background-color: #000;
                    color: $whiteColor2;

                    outline: none;
                    border: none;

                    .profile-btn-icon {
                        width: 30px;
                        border-radius: 50%;
                    }
                }
                
                width: 75%;
                min-width: 150px;
                padding: 0.6rem 1rem;

                text-align: center;
                border-radius: 40px;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                @media (max-width: 500px) {
                    width: 90%;
                }
            }
        }
    }

    .profile-package {
        width: 90%;
        height: 100px;
        border: 1px solid $baseColor1;
        border-radius: 15px;
        
        background-color: $whiteColor2;

        
    }

    .profile-menu {
        width: 90%;

        .menu-title {
            color: $grayColor;
        }
        
        .menu-list {

            border: 1px solid $shadeColor2;
            border-radius: 15px;
            width: 100%;
            background-color: $whiteColor2;

            .menu-item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                width: 100%;
                border: none;
                outline: none;
                background-color: rgba(0, 0, 0, 0);

                text-decoration: none;
                color: initial;
                padding: 1rem;

                .icon-wrapper {
                    .fa-icon {
                        margin-right: 4px;
                        color: $baseColor1;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $shadeColor2;
                }
            }
        }

        &.profile-account-management {}

        &.profile-misc {}
    }

}