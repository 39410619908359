$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$shadeColor2: var(--base-shade-color-2);

$whiteColor: var(--white-color);
$whiteColor2: var(--white-color-2);
$darkTextColor: var(--dark-text-color);
$grayColor: var(--gray-text-color);
$dangerColor: var(--danger-color);

$whiteTextColor: var(--secondary-text-color);

.account-detail-page {
    padding: 80px 0 60px;

    background: linear-gradient(180deg, $baseColor4, $whiteColor);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

    .page-form {
        width: 90%;

        .form-label {
            margin-top: 0.75rem;
        }

        .form-input {
            width: 100%;

            padding: 0.7rem;
            border-radius: 10px;

            outline: none;
            border: 1.5px solid $grayColor;

            &.form-error {
                border-color: $dangerColor;
            }
        }

        .text-validation {
            display: block;
        }

        .form-button {
            width: 100%;
            padding: 0.5rem;
            border-radius: 40px;
            outline: none;
            border: none;

            margin-top: 4rem;

            background: $baseColor1;
            background: linear-gradient(120deg, $baseColor1, $baseColor2);

            color: $whiteColor2;

            &:disabled {
                background: $grayColor;
            }
        }
    }

    .btn-delete-account {
        width: 90%;
        padding: 0.5rem;

        border-radius: 40px;

        background: rgba(0, 0, 0, 0);
        outline: 1px solid $dangerColor;
        border: none;
        color: $dangerColor;

        font-weight: 700;
    }

    .success-toast-body {
        display: flex;
        justify-content: space-between;
    }
}

.account-deletion-modal {
    font-family: 'JosefinSans';
    
    .modal-content {
        background-color: $whiteTextColor;

    }

    .modal-title {
        span {
            margin-left: 5px;
            margin-top: 2px;
        }
    }

    .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .qty-input {
            display: block;
            width: 100px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.375rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .qty-select {
            display: block;
            width: 100px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.375rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .qty-unit {

        }
    }

    .modal-footer {
        justify-content: center;

        .btn-cancel {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;

            background: $baseColor1;
            background: linear-gradient(60deg, $baseColor2 10%, $baseColor1 100%);
            color: $whiteColor2;
        }

        .btn-confirm {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;
        }
    }
}