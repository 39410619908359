$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);
$contentBackgroundColor: #FBFBF8;

.event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    background-color: $contentBackgroundColor;
    border: 1px solid $boxOutlineColor;
    text-decoration: none;

    padding: 5px;
    border-radius: 10px;
    width: 100%;

    .event-banner {
        width: 100%;
        height: 120px;
        overflow: hidden;
        // background-color: $grayTextColor;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;

        img {
            width: 100%;
        }
    }

    .event-content {
        width: 100%;
        padding: 0.5rem 0.5rem;

        .event-name {
            font-size: 14px;
            color: $darkTextColor;
            line-height: 17px;
            font-weight: 600;

            margin-bottom: 7px;
        }

        .event-desc {
            font-size: 12px;
            color: $grayTextColor;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}