$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.business-entity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    gap: 1rem;
    width: 100%;
    padding: 0 2rem;

    .business-entity-subtitle {
        align-self: flex-start;
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 0.7rem;
    }
    
    .business-entity-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 100%;

        .business-entity-item {
            background-color: $whiteColor2;

            width: 100%;
            border: 1px solid $boxOutlineColor;
            border-radius: 7px;
            padding: 16px 15px;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            cursor: pointer;

            &.active {
                border-color: $baseColor1;
            }

            .additional-price {
                display: flex;
                align-items: center;
                gap: 1rem;
                
                font-size: 17px;
                
                .price-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    width: 100%;

                    .price-label {
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        color: $grayTextColor;
                    }

                    .price-number {
                        display: flex;
                        align-items: center;
                        gap: 0.7rem;
    
                        font-weight: 700;

                        .real {
                            text-decoration: line-through;
                            // text-decoration-color: $baseColor1;
                        }
                    }
                }

            }
        }
    }

    .business-entity-extra {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .business-entity-add-ons {
            background-color: $defaultBgColor;
            // background-color: $baseColor4;
            padding: 16px 1.5rem;
            border: 2px solid $baseColor1;
            border-radius: 20px;

            .add-ons-title {
                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                }
            }

            .add-ons-list {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .add-ons-items {
                    font-size: 20px;

                    .add-ons-price {
                        font-weight: 700;
                    }
                }
            }
        }

        .business-entity-vo {
            background-color: $defaultBgColor;
            // background-color: $baseColor4;
            padding: 16px 1.5rem;
            border: 2px solid $baseColor1;
            border-radius: 20px;

            .vo-title {
                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                }
            }

            .vo-list {
                .vo-items {
                    font-size: 20px;
                }
            }
        }
    }

    .business-entity-footer {
        position: fixed;
        bottom: 0;
        max-width: 500px;

        background-color: $whiteColor2;
        width: 100%;
        padding: 1rem;
        box-shadow: 0px -4px 10px rgba(111, 118, 136, 0.1);

        .notes {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 1rem;
            color: $grayTextColor;
        }

        .btn-choose {
            background: $baseColor1;
            background: linear-gradient(40deg, $baseColor2 50%, $baseColor1 100%);

            outline: none;
            border: none;

            color: $baseColor4;
            border-radius: 50px;
            width: 100%;
            padding: 1rem;
        }
    }

}