$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.box-detail {
    width: 100%;
    background-color: $whiteColor2;

    padding: 1.5rem 1rem;
    border: 1px solid $boxOutlineColor;
    border-radius: 10px;
    overflow: hidden;

    .detail-header {
        border-bottom: 1px solid $boxOutlineColor;
        padding-bottom: 5px;
        margin-bottom: 10px;
        color: $darkTextColor;
        font-size: 17px;
        font-weight: 600;
    }
    
    &.form {
        padding: 0 1rem;
        cursor: pointer;

        .detail-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .label {
                min-width: 200px;
                display: flex;
                align-items: center;
                gap: 5px;

                line-height: 20px;
                padding: 0.7rem 0;

                img {
                    width: 20px;
                }

                @media (max-width: 470px) {
                    min-width: 0;
                }
            }

            .input {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                gap: 10px;

                input {
                    text-align: right;
                    border: none;
                    outline: none;
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    
                    padding: 0.7rem;
                }
            }

            .input-display {
                padding: 0.7rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
            }
        }
    }

    .detail-body {
        .list-item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .item-price {
                text-align: right;
            }

        }

        @media (max-width: 400px) {
            .list-item {
                flex-direction: column;
                justify-content: center;

                .item-label {
                    font-weight: 600;
                }
                
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.input-modal {
    font-family: 'JosefinSans';

    .modal-header {
        font-weight: 600;
        
        .modal-header-icon {
            margin-right: 10px;
            width: 25px;

            img {
                width: 100%;
            }
        }
    }

    .modal-body {
        .single-input {
            width: 100%;
            height: 200px;

            background-color: rgba(0, 0, 0, 0);
            border: none;
            outline: none;
        }

        .options-input {
            display: flex;
            gap: 1rem;

            .option-input {
                border: 1px solid $baseColor1;
                padding: 0.3rem 0;
                width: 40px;
                text-align: center;
                border-radius: 10px;
                
                cursor: pointer;
                
                &.active {
                    font-weight: 700;
                    color: $baseColor1;
                }
            }
        }

        .multi-input {
            .multi-input-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 15px;

                .single-input {
                    background-color: $whiteTextColor;
                    border: 1px solid $boxOutlineColor;
                    height: fit-content;
                    width: 100px;
                    padding: 0.3rem 0.5rem;
                }
            }
        }
    }

    .modal-footer {
        justify-content: center;

        .btn-cancel {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;
        }

        .btn-confirm {
            width: 40%;
            border: none;
            outline: none;
            padding: 0.5rem;
            border-radius: 30px;

            background: $baseColor1;
            background: linear-gradient(60deg, $baseColor2 10%, $baseColor1 100%);
            color: $whiteColor2;

            &:disabled {
                opacity: 0.7;
            }
        }
    }
}

