$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$whiteColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);
$darkTextColor: var(--dark-text-color);
$grayTextColor: var(--gray-text-color);

$contentBackgroundColor: #FBFBF8;
$outlineColor: var(--base-shade-color-2);

.event {
    padding: 0.8rem 0 1rem;
    
    .event-header {
        .event-title {
            margin: 0 2rem 0.7rem;
            font-family: 'Ubuntu';
            font-weight: 500;
            font-size: 1.7rem;
            color: $darkTextColor;
        }
    
        .event-subtitle {
            font-size: 1rem;
            margin: 0 2rem;
            color: $grayTextColor;
        }
    }

    .event-carousel-container {
        width: 100%;
        height: fit-content;
        padding: 0.6rem 2rem;

        .event-item-holder {
            // width: 100px;
            padding-right: 20px;
            // margin: 0 auto;

            .event-choice {
                text-decoration: none;

                .event-item {
                    width: 90%;

                    .event-banner {
                        height: 80px;
                    }
                }
            }
        }
    }

    .event-footer {
        padding: 0.3rem 2rem;

        .event-see-more {
            float: right;
            margin-bottom: 25px;

            color: $baseColor2;
        }
    }

    @media (max-width: 500px) {
        .event-header {
            .event-title {
                font-size: 19px;
            }
        } 
    }
}