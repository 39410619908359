$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$topBarColor: var(--white-color);
$darkTextColor: var(--dark-text-color);
$whiteColor: var(--white-color);

.event-detail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    background: linear-gradient(180deg, $baseColor4, $whiteColor);

    margin-top: 60px;
    padding: 20px 0 170px;

    .event-header {
        width: 90%;

        .event-name {
            font-size: 24px;
            font-weight: 600;
        }

        .event-created-date {
            font-size: 14px;
        }
    }

    .event-banner {
        width: 90%;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);

        img {
            width: 100%;
        }
    }

    .event-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 90%;

        .event-desc {
            font-size: 16px;
        }

        .event-detail {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;

            .event-timerange {

            }

            .event-participant-limit {

            }

            .event-registration-deadline {

            }
        }
    }

    .event-loading-container {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        
        gap: 3rem;
        width: 100%;
        min-height: 500px;

        padding: 0 2rem;
    }
}