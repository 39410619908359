$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$navTextColor: var(--gray-text-color);
$navBorderColor: #F5F7F9;
$navBackgroundColor: var(--white-color-2);

.navbar {
    position: fixed;
    z-index: 1002;
    width: 100%;
    max-width: 500px;
    padding: 10px 20px;
    
    &#top-bar {
        height: 60px;
        top: 0;
        background: $baseColor1;
        background: linear-gradient(60deg, $baseColor2 10%, $baseColor1 100%);
    }

    &#navigation-bar {
        height: 70px;
        bottom: 0;
        background: $navBackgroundColor;
        box-shadow: 0px -4px 10px rgba(111, 118, 136, 0.1);
    }

    .navbar-brand {
        font-family: 'Ubuntu';
        font-weight:  700;
        color: $baseColor3;

        .nav-logo {
            width: 120px;
            // padding: 10px 0;
        }
    }

    .navbar-nav {
        position: relative;
        height: 100%;
        width: 100%;

        .nav-link {
            color: $navTextColor;
            
            &:hover, &:focus, &:active {
                border-color: $baseColor3;
            }
        }

    }
}