$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$topBarColor: var(--white-color);
$darkTextColor: var(--dark-text-color);

.success-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    // height: 100vh;

    padding-top: 60px;
    padding-bottom: 200px;
    overflow-y: auto;
    
    .success-page-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;

        padding: 0 2rem;
    }
}