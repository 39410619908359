$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);

.header-container {
    padding: 0 0 1rem;
    
    .header-item-holder {
        padding: 1.5rem 0;
        min-height: 236px;

        .header {
            // background: $contentBackgroundColor;    
            width: 90%;
            border-radius: 20px;
            box-shadow: 2px 5px 10px rgba(111, 118, 136, 0.2);
            height: 93%;
            
            display: flex;
            align-items: center;
            justify-content: center;
            // flex-direction: column;

            margin: 0 auto;
            
            // position: relative;
            overflow: hidden;

            .header-content {
                overflow: hidden;
                width: 100%;
            }
        }

    }

    .header-dots {
        gap: 0.5rem;

        .react-multi-carousel-dot {
            button {
                background-color: $baseColor1;
                border: none;
                opacity: 0.7;
            }

            &--active {
                button {
                    opacity: 1;
                }
            }
        }
    }
}