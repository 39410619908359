$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$shadeColor2: var(--base-shade-color-2);

$whiteColor: var(--white-color);
$whiteColor2: var(--white-color-2);
$darkTextColor: var(--dark-text-color);
$grayColor: var(--gray-text-color);

$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$shadeColor2: var(--base-shade-color-2);

$whiteColor: var(--white-color);
$whiteColor2: var(--white-color-2);
$darkTextColor: var(--dark-text-color);
$grayColor: var(--gray-text-color);
$dangerColor: var(--danger-color);

.profile-detail-page {
    padding: 80px 0 60px;

    background: linear-gradient(180deg, $baseColor4, $whiteColor);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

    .page-form {
        width: 90%;

        .form-label {
            margin-top: 0.75rem;

        }

        .form-input {
            width: 100%;

            padding: 0.7rem;
            border-radius: 10px;

            outline: none;
            border: 1.5px solid $grayColor;

            &.form-error {
                border-color: $dangerColor;
            }
        }

        .text-validation {
            display: block;
        }

        .form-button {
            width: 100%;
            padding: 0.5rem;
            border-radius: 40px;
            outline: none;
            border: none;

            margin-top: 4rem;

            background: $baseColor1;
            background: linear-gradient(120deg, $baseColor1, $baseColor2);

            color: $whiteColor2;

            &:disabled {
                background: $grayColor;
            }
        }
    }

    .success-toast-body {
        display: flex;
        justify-content: space-between;
    }
}