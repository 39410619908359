$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$navTextColor: var(--gray-text-color);
$navBorderColor: #F5F7F9;
$navBackgroundColor: var(--white-color-2);

.bottom-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .nav-link {
        &#konsultasi-sekarang {
            position: absolute;
            top: -70px;
            left: 35%;
            margin: 0 auto;
            // width: 100%;

            align-self: center;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 6px;

            .icon-wrapper {
                // padding: 1rem 1.25rem 0.7rem;
                // color: $navBackgroundColor;
                // font-size: 25px;
                
                width: 75px;
                border-radius: 50%;
                background-color: $baseColor2;
                border: 4px solid $navBorderColor;

                box-shadow: 0px -6px 10px rgba(111, 118, 136, 0.1);

                img {
                    width: 100%;
                }
            }

            font-size: 14px;
        }
    }

    .bottom-nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;

        font-size: 14px;

        &.active {
            color: $baseColor1;
        }

        .icon-wrapper {
            width: 25px;

            img {
                width: 100%;
            }
        }
    }
}