$darkTextColor: var(--dark-text-color);
$buttonBackgroundColor: #FDFDF8;
$outlineColor: var(--base-shade-color-2);

.whatsapp-button-container {
    padding: 1rem 2rem 0;
    width: 100%;

    .whatsapp-button {
        width: 100%;
        text-decoration: none;
        color: $darkTextColor;
        background-color: $buttonBackgroundColor;
        border: 1px solid $outlineColor;
        padding: 1rem;

        display: flex;
        align-items: center;
        border-radius: 10px;

        .whatsapp-icon {
            width: 25px;
            margin-right: 5px;
        }

        .button-label {
            width: 100%;
        }

        .whatsapp-button-icon {
            
        }
    }

    @media (max-width: 500px) {
        .whatsapp-button {
            font-size: 15px;
            
            .whatsapp-icon {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
}