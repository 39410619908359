.business-entity-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    gap: 1rem;
    width: 100%;
    padding: 0 2rem;

    .copy-toast-body {
        display: flex;
        justify-content: space-between;
    }
}