$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);
$whiteColor2: var(--white-color-2);
$boxOutlineColor: var(--base-shade-color-2);
$grayTextColor: var(--gray-text-color);

.service-header {
    width: 100%;
    background-color: $whiteColor2;

    // padding: 1rem;
    border: 1px solid $boxOutlineColor;
    border-radius: 10px;
    overflow: hidden;

    .accordion-header {
        .accordion-button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            &:focus {
                border-color: initial;
            }
            
            &:not(.collapsed) {
                background-color: initial;
                color: initial;
                box-shadow: none;

                &::after {
                    background-image: var(--bs-accordion-btn-icon);;
                }
            }
        }
    }

    .service-icon {
        min-width: 60px;
        width: 10%;
        // background-color: $baseColor2;
        // border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .service-name {
        margin-bottom: 0;
        font-size: 1.1rem;
        color: $grayTextColor;
    }
}