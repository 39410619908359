$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$topBarColor: var(--white-color-2);
$darkTextColor: var(--dark-text-color);

.topbar {
    background-color: $topBarColor;
    color: $darkTextColor;
    width: 100%;
    max-width: 500px;
    height: 60px;
    
    position: fixed;
    z-index: 1002;
    padding: 17px 20px;
    top: 0;
    
    font-size: 1.2rem;
    font-weight: 500;
    
    box-shadow: 0px 4px 10px rgba(111, 118, 136, 0.1);

    .back-icon {
        margin-right: 20px;
        cursor: pointer;
    }
}

#top-bar {
    justify-content: space-between;
    padding: 0 0 0 20px;
    
    .top-right-menu {
        display: flex;
        align-items: center;
        // gap: 1rem;
        color: $baseColor4;
        margin-right: 15px;

        .top-menu-item {
            cursor: pointer;

            padding: 17px 10px;
            
            display: flex;
            align-items: center;
            gap: 5px;

            transition: all 0.5s ease;
            
            .top-menu-profile-picture {
                width: 30px;
                border-radius: 50%;
            }

            .top-menu-icon {
                font-size: 25px;
            }
            
            &:hover {
                // background-color: rgba(255, 255, 255, 0.3);
                opacity: 0.7;
            }
        }
    }
}