$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkTextColor: var(--dark-text-color);
$grayTextColor: var(--gray-text-color);
$contentBackgroundColor: #FBFBF8;
$outlineColor: var(--base-shade-color-2);

$primaryTextColor: var(--primary-text-color);

.corporate-package {
    padding: 0.8rem 0 1rem;
    
    .corporate-package-title {
        margin: 0 2rem 0.7rem;
        font-family: 'Ubuntu';
        font-weight: 500;
        font-size: 1.7rem;
        color: $darkTextColor;
    }

    .corporate-package-subtitle {
        font-size: 1rem;
        margin: 0 2rem;
        color: $grayTextColor;
    }

    .corporate-package-list {
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        gap: 1rem;
        padding: 0 2rem;

        .corporate-package-item {
            position: relative;
            width: 100%;
            max-width: 150px;

            background-color: $contentBackgroundColor;
            border: 1px solid $outlineColor;
            text-decoration: none;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: baseline;
            gap: 1rem;

            position: relative;
            
            border-radius: 10px;
            
            padding: 0.6rem 0.5rem;

            &.v2 {
                max-width: unset;

                .corporate-package-item-title {
                    font-size: 16px;
                }

                .corporate-package-item-desc {
                    text-decoration: none;
                    color: initial;
                    font-size: 14px;
                }
            }

            .corporate-package-item-title {
                color: $darkTextColor;
                font-size: 12px;

                display: flex;
                align-items: center;
                text-align: center;
                justify-content: space-evenly;
            }

            .corporate-package-item-icon {
                width: 60px;

                // background-color: $baseColor3;
                border-radius: 50%;

                img {
                    max-width: 100%;
                    // margin-bottom: 0.8rem;
                }
            }
        }
    }

    .corporate-package-notes {
        align-self: flex-start;
        margin-left: 2.5rem;

        &-item {
            display: block;
            font-size: 13px;
            color: $darkTextColor;
        }
    }

    @media (max-width: 500px) {
        .corporate-package-title {
            font-size: 19px;
        }
        
        .corporate-package-subtitle {
            font-size: 14px;
        }
    }
}