$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$topBarColor: var(--white-color);
$darkTextColor: var(--dark-text-color);

.legal-service-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

    margin-top: 60px;
    padding-bottom: 170px;
    
    .legal-service-loading-container {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        
        gap: 3rem;
        width: 100%;
        min-height: 500px;

        padding: 0 2rem;
    }
}